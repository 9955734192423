// NOTE: hooks
import React, { lazy, Suspense, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
// NOTE: components
import Layout from '../../components/ui/Layout'
import Loading from '../../components/ui/Loading'
import ManagerSubPage from '../../components/ui/ManagerSubPage'
// NOTE: images
import homeIcon from '../../assets/img/twd/home.png'
import settingsIcon from '../../assets/img/twd/settings.png'
import exploreIcon from '../../assets/img/twd/resources.png'
import useManagerAuthorisation from './useManagerAuthorisation'
// NOTE: main pages
const Settings = lazy(() => import('./Settings'))
const HealthOverview = lazy(() => import('./HealthOverview'))
const FinanceOverview = lazy(() => import('./FinanceOverview'))
const Managers = lazy(() => import('./Managers'))
const Explore = lazy(() => import('./Explore'))
const Onboarding = lazy(() => import('./Onboarding'))
const Setup = lazy(() => import('./Setup'))
const Staff = lazy(() => import('./Staff'))
const CreateTeam = lazy(() => import('./CreateTeam'))

const navLinks = [
	{
		to: '/manager/team',
		text: 'Home',
		icon: homeIcon
	},
	{
		to: '/manager/explore',
		text: 'Explore',
		icon: exploreIcon
	},
	{
		to: '/manager/settings',
		text: 'Settings',
		icon: settingsIcon
	}
]

const homeLinks = [
	{
		to: 'staff',
		text: 'Staff'
	},
	{
		to: 'health',
		text: 'Health'
	},
	{
		to: 'finance',
		text: 'Finance'
	},
	{
		to: 'managers',
		text: 'Managers'
	},
	{
		to: 'setup',
		text: 'Setup'
	}
]

interface ProtectedManagerProps {
	isAuthorisationNeeded?: boolean
}

const ProtectedManager = ({ isAuthorisationNeeded = true }: ProtectedManagerProps) => {
	const { loading, getManagerData } = useManagerAuthorisation()

	useEffect(() => {
		getManagerData(isAuthorisationNeeded)
	}, [])

	if (loading) return <Loading size={undefined} />

	return <Outlet />
}

// NOTE: component to show loading animation
const LoadPage = ({ page }: any) => (
	<Suspense fallback={<Loading size={undefined} />}>{page}</Suspense>
)

const managerRoutes = [
	{
		index: true,
		element: <Navigate to="/manager/" />
	},
	{
		element: <ProtectedManager />,
		children: [
			{
				path: 'manager',
				element: <Layout links={navLinks} home="/manager/" dashboard="manager" />,
				children: [
					{
						index: true,
						element: <Navigate to="team" />
					},
					{
						path: 'team',
						element: <ManagerSubPage title="Home" src={homeIcon} links={homeLinks} />,
						children: [
							{
								index: true,
								element: <Navigate to="staff" />
							},
							{
								path: 'staff',
								element: <LoadPage page={<Staff />} />
							},
							{
								path: 'health',
								element: <LoadPage page={<HealthOverview />} />
							},
							{
								path: 'finance',
								element: <LoadPage page={<FinanceOverview />} />
							},
							{
								path: 'managers',
								element: <LoadPage page={<Managers />} />
							},
							{
								path: 'setup',
								element: <LoadPage page={<Setup />} />
							}
						]
					},
					{
						path: 'explore',
						element: <LoadPage page={<Explore />} />
					},
					{
						path: 'settings',
						element: <LoadPage page={<Settings />} />
					}
				]
			}
		]
	},
	{
		path: 'manager',
		element: <ProtectedManager isAuthorisationNeeded={false} />,
		children: [
			{
				path: 'onboarding',
				element: <LoadPage page={<Onboarding />} />
			},
			{
				path: 'create-team',
				element: <LoadPage page={<CreateTeam />} />
			}
		]
	}
]

export default managerRoutes
