// NOTE: this is throw away code and should be re-using the Navbar component as the only change is the team logo and TWD logo at the bottom
// TODO: clean this component
// TODO: clean this component and break down in a folder

// NOTE: hooks
import { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { useAuthContext } from '../../hooks/useAuthContext'
// NOTE: components
import Module from './Card'
import Modal from './Modal'
import Tooltip from '@mui/material/Tooltip'
// NOTE: images
import logoutIcon from '../../assets/img/twd/logout.png'
import settingsIcon from '../../assets/img/twd/settings.png'
//import setupIcon from '../../assets/img/twd/submit.png'
import twdRectangleLogo from '../../assets/img/logo/TWDLongLogo_lighterText.png'
import twdIcon from '../../assets/img/logo/twd.png'
import avatarPlaceholderImage from '../../assets/img/placeholder.png'
import companyIcon from '../../assets/img/twd/company.png'
import companyPlaceholderImage from '../../assets/img/placeholder-logo.png'

const ManagerNavBar = ({ links, home }) => {
	const { user } = useAuthContext()
	const { logout } = useAuth()
	const [show, setShow] = useState(false)

	return (
		<>
			<Modal show={show} onHide={() => setShow(false)} size="sm">
				<Modal.Header onHide={() => setShow(false)}>Hi, {user.fname}!</Modal.Header>

				<div className="border-b-2 border-[#f6f8f9] p-4">
					<NavLink
						className="flex-fill flex w-full flex-col"
						to="/dashboard/account"
						onClick={() => setShow(false)}
						target="_blank"
					>
						<img
							src={user.profilePicture ?? avatarPlaceholderImage}
							alt="Profile"
							className="mx-auto h-28 rounded-full border-2 border-[#66CCCC] p-1 shadow-inner"
							loading="eager"
						/>
						<p className="font-regular mt-2 text-center text-lg">{user.email}</p>
					</NavLink>
				</div>

				<div className="p-4">
					<NavLink
						className="my-2 flex px-0 md:px-4"
						to="/manager/team/setup"
						onClick={() => setShow(false)}
					>
						<img src={companyIcon} className="mr-2 h-14" alt="Settings" />
						<p className="my-auto pb-1 text-xl">Complete your business setup</p>
					</NavLink>

					<a
						className="my-2 flex px-0 md:px-4"
						href="https://app.behelfy.com/account/profile"
						target="_blank"
						rel="noreferrer"
					>
						<img src={settingsIcon} className="mr-2 h-14" alt="Settings" />
						<p className="my-auto pb-1 text-xl">Personal settings</p>
					</a>

					<button className="my-2 flex w-full px-0 md:px-4" onClick={logout}>
						<img src={logoutIcon} className="mr-2 h-14" alt="Logout" />
						<p className="my-auto pb-1 text-xl">Log out</p>
					</button>
				</div>
			</Modal>

			<nav>
				<PhoneNav
					links={links}
					home={home}
					handleLogOut={logout}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					openAccount={() => setShow(true)}
					className="block md:hidden"
				/>

				<DeskNav
					links={links}
					home={home}
					handleLogOut={logout}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					companyLogo={user.team?.companyLogo ?? companyPlaceholderImage}
					openAccount={() => setShow(true)}
					className="hidden md:block"
				/>
			</nav>
		</>
	)
}

const PhoneNav = ({ links, profilePicture, openAccount, className }) => {
	return (
		<Module
			className={`${className} no-scrollbar fixed bottom-0 z-20`}
			style={{
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 0px 24px',
				overflowX: 'scroll',
				border: 'none',
				borderRadius: '0',
				width: '100%'
			}}
		>
			<div className="flex justify-around p-2">
				{links.map((link) => (
					<div key={link.to}>
						<NavLink
							className={({ isActive }) =>
								`${isActive ? 'active' : null} nav-link flex flex-col p-2`
							}
							to={link.to}
							//end={link.text?.toLowerCase() === 'home'} TODO: put this back once a standalone home is added to managers
						>
							{({ isActive }) => (
								<Tooltip title={link.text}>
									<img
										src={link.icon}
										style={{ height: 40, width: 40, minWidth: 40 }}
										alt="Link icon"
										className={`mx-auto ${isActive ? 'opacity-100 saturate-100' : 'opacity-80 saturate-50'}`}
										loading="eager"
									/>
								</Tooltip>
							)}
						</NavLink>
					</div>
				))}
				<button onClick={openAccount}>
					{profilePicture && (
						<Tooltip title="Account">
							<img
								src={profilePicture}
								style={{
									width: 40,
									height: 40,
									maxWidth: 40,
									maxHeight: 40,
									borderRadius: '50%',
									padding: 2,
									border: '1.5px solid #66CCCC'
								}}
								className="shadow-inner"
								alt="Profile"
								loading="eager"
							/>
						</Tooltip>
					)}

					{!profilePicture && (
						<div
							className="skeleton"
							style={{
								width: 40,
								height: 40,
								maxWidth: 40,
								maxHeight: 40,
								borderRadius: '50%',
								padding: 2,
								border: '1.5px solid #66CCCC'
							}}
						/>
					)}
				</button>
			</div>
		</Module>
	)
}

const DeskNav = ({ links, home, profilePicture, openAccount, className, companyLogo }) => {
	const { user } = useAuthContext()

	return (
		<div
			style={{
				height: '100vh',
				flexShrink: 0,
				backgroundColor: '#f1f1f1',
				position: 'sticky',
				bottom: 0,
				top: 0
			}}
			className={`${className} w-[110px] lg:w-[260px]`}
		>
			<div
				style={{
					backgroundColor: 'white',
					border: '2px solid rgba(51, 66, 81, 0.1)',
					width: '100%',
					height: '100%'
				}}
			>
				<div className="flex flex-col p-2" style={{ height: '100%', overflowY: 'scroll' }}>
					<Link to={home} className="mx-auto mb-8 mt-6 w-full px-2 lg:px-4">
						{companyLogo && (
							<img
								src={companyLogo}
								alt={user.team?.name ?? 'Business'}
								className="mx-auto max-h-[65px] lg:max-h-[120px]"
								loading="eager"
							/>
						)}
						{!companyLogo && <div className="skeleton h-[78px] w-full rounded" />}
					</Link>

					{links.map((link) => (
						<div key={link.to}>
							<NavLink
								className={({ isActive }) =>
									`${isActive ? 'active' : null} nav-link mt-4 flex px-4 py-2`
								}
								to={link.to}
								//end={link.text?.toLowerCase() === 'home'} TODO: put this back once a standalone home is added to managers
							>
								{({ isActive }) => (
									<>
										<img
											src={link.icon}
											className={`mx-auto h-[55px] w-[55px] lg:mx-0 lg:h-[40px] lg:w-[40px] ${isActive ? 'opacity-100 saturate-100' : 'opacity-80 saturate-50'}`}
											alt={link.text}
											loading="eager"
										/>
										<span className="my-auto hidden pl-4 lg:inline">{link.text}</span>
									</>
								)}
							</NavLink>
						</div>
					))}

					<div className="mt-auto flex flex-col">
						<button className="nav-link mb-8 mt-4 flex px-4 py-2" onClick={openAccount}>
							{profilePicture && (
								<img
									src={profilePicture}
									style={{
										borderRadius: '50%',
										padding: 2,
										border: '1.5px solid #66CCCC'
									}}
									className="mx-auto h-[55px] w-[55px] shadow-inner lg:mx-0 lg:h-[40px] lg:w-[40px]"
									alt="Profile"
									loading="eager"
								/>
							)}
							{!profilePicture && (
								<div
									style={{
										borderRadius: '50%',
										padding: 2,
										border: '1.5px solid #66CCCC'
									}}
									className="skeleton mx-auto h-[55px] w-[55px] lg:mx-0 lg:h-[40px] lg:w-[40px]"
								/>
							)}
							<span className="my-auto hidden pl-4 lg:inline">Account</span>
						</button>

						<hr />

						<div className="my-2 px-3 py-2">
							<img
								src={twdRectangleLogo}
								alt="The Wellbeing Doctors"
								className="mx-auto hidden lg:block"
							/>

							<img
								src={twdIcon}
								alt="The Wellbeing Doctors"
								className="mx-auto block h-[48px] w-[48px] lg:hidden"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManagerNavBar
