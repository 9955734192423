// NOTE: this is throw away code and should be re-using the SubPage component as the only change is the Welcome to team name
// TODO: clean this component
import { Outlet } from 'react-router-dom'
import Container from './Container'
import PageTitle from './PageTitle'
import { useAuthContext } from '../../hooks/useAuthContext'
import { NavLink } from 'react-router-dom'
// components
import { Carousel, SwiperSlide } from './carousel/Carousel'

const SubPageManager = ({ title, src, links }) => {
	const { user } = useAuthContext()

	return (
		<Container className="py-4 md:py-6">
			<div className="mb-2 flex flex-wrap justify-between gap-x-4">
				<PageTitle title={title} src={src} />
				<p className="my-auto ml-auto text-lg">{`Hi ${user.fname}${user.team ? `, welcome to ${user?.team?.name}.` : '!'}`}</p>
			</div>

			<Carousel className="mb-4 md:mb-6">
				{links.map((link, index) => (
					<SwiperSlide style={{ width: 'fit-content', minWidth: 'fit-content' }} key={index}>
						<NavLink
							to={link.to}
							className={({ isActive }) =>
								`${isActive ? 'active' : ''} sublink mr-4 flex w-fit flex-col`
							}
						>
							<h2 className="text-xl md:text-2xl">{link.text}</h2>
							<div className="h-[2px] w-full rounded-2xl md:h-[2.5px]" />
						</NavLink>
					</SwiperSlide>
				))}
			</Carousel>

			<Outlet />
		</Container>
	)
}

export default SubPageManager
