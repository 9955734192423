// TODO: check where it's being used - have the useAuthContext hook in the same file

import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom'
import * as healthApi from '../api/health/auth'

export const useAuth = () => {
	const navigate = useNavigate()
	const { dispatch } = useAuthContext()

	// NOTE: remove user from storage and dispatch logout action
	const logout = () => {
		// TODO: improve this when implementing auth.js
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
		// TODO: import redirect to auth hook to keep the search consistent - the redirect is used in protected routes as well
		const prevSearch = window.location.search
		const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`
		navigate(`/auth${search}`)
	}

	const login = async (email, password, route) => {
		const data = { email, password, route }

		return await healthApi.login(data)
	}

	const register = async (data) => {
		return await healthApi.register(data)
	}

	const resetPwd = async (email, url) => {
		const data = { email, url }
		return await healthApi.sendToken(data)
	}

	const newPwd = async (email, token, password) => {
		const data = { email, token, password }
		return await healthApi.resetPwd(data)
	}

	return { login, register, newPwd, resetPwd, logout }
}
