// NOTE: hooks
import { useAuthContext } from '../../hooks/useAuthContext'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import useRedirectToAuth from '../../hooks/useRedirectToAuth'
import { useAuth } from '../../hooks/useAuth'
// NOTE: components
import Layout from '../../components/ui/Layout'
import Loading from '../../components/ui/Loading'
// NOTE: images
import homeIcon from '../../assets/img/navIcons/homeGradient.png'
import adminIcon from '../../assets/img/logo/adminIcon.png'
// NOTE: main pages
const Home = lazy(() => import('./Home'))

const navLinks = [
	{
		to: '/admin/',
		text: 'Home',
		icon: homeIcon
	}
]

// NOTE: component to show loading animation
const LoadPage = ({ page }: any) => (
	<Suspense fallback={<Loading size={undefined} />}>{page}</Suspense>
)

const ProtectedAdmin = () => {
	const redirectToAuth = useRedirectToAuth()
	const { logout } = useAuth()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		// TODO: use the user object from the context instead of getting it from local storage

		// NOTE: get the saved user object from local storage from previous logins
		const userLocalStorage = localStorage.getItem('user')
		const savedUser = userLocalStorage && JSON.parse(userLocalStorage)

		if (!savedUser) return redirectToAuth()

		// NOTE: when user use authenticated with the old token structure - this won't be needed with auth.js
		if (savedUser.displayName || savedUser.position) return logout()

		// NOTE: check user role and permission
		if (!savedUser.admin) return logout()

		setLoading(false)
	}, [])

	if (loading) return <Loading size={undefined} />

	return <Outlet />
}

const adminRoutes = [
	{
		element: <ProtectedAdmin />,
		children: [
			{
				path: 'admin',
				element: <Layout links={navLinks} home="/admin/" icon={adminIcon} dashboard={''} />,
				children: [
					{
						index: true,
						element: <LoadPage page={<Home />} />
					}
				]
			}
		]
	}
]

export default adminRoutes
